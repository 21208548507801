<template>
  <div class="content">
    <el-row class="bgW" style="text-align: right;">
      <el-button type="primary" round size="mini" @click="tapBtn('')">添加仓库</el-button>
    </el-row>
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn type="index" label="序号" width="60" />
            <ElTableColumn label="仓库名称" prop="name" />
            <ElTableColumn label="归属市场" prop="marketName" />
            <ElTableColumn label="金蝶编码" prop="kingdeeCode" />
            <ElTableColumn label="操作" width="180" fixed="right">
              <template slot-scope="{ row }">
                <span class="theme" @click="tapBtn(row.id)">编 辑</span>
              </template>
            </ElTableColumn>
          </el-table>

          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/Pagination";
import { readAdminStoreList } from "@/api/shop";

class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  name: "ShopList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(), // 分页
      list: [] // 数据展示
    };
  },

  mounted() {
    this.getList();
  },
  activated() {},
  methods: {
    // 获取列表
    getList() {
      readAdminStoreList({ ...this.page }).then(res => {
        this.list = res.list;
        this.page.total = res.dataCount;
      });
    },
    // 点击详情
    tapBtn(id) {
      this.$router.push("./addStorage?&id=" + id);
    }
  }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 40px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
</style>
